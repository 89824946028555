import React, { useState, useEffect, useRef } from "react"
import { useSpring, useTransition, animated as a } from "react-spring"
import {removeBreaks, removeEmptyElements, maxWordCount} from "../Hooks/useWordCounter"

const HeadlineInput = ({register, setHeadline, headline, ...props}) => {
    const maxWords = 5
    const [charCount, setCharCount] = useState(0)
    const [wordCount, setWordCount] = useState(0)

    const setCounts = value => {
        const trimmedValue = value.trim();
        let words = removeEmptyElements(trimmedValue)
        words = removeBreaks(trimmedValue)
        words = trimmedValue.split(' ');

        setHeadline(value)
        setCharCount(trimmedValue.length)
        setWordCount(value === '' ? 0 : words.length)
    }

    const handleChange = e => {
        setCounts(e.target.value)
    };

    return (
        <>
        <label htmlFor="message" className="uk-text-small uk-text-muted uk-margin-small-bottom">
            <div className="uk-grid uk-grid-collapse">
                <div className="uk-width-expand">
                    <small>
                        <span className="uk-text-bold uk-text-primary">* Optional.</span> Up to <span className="uk-text-bold">{!wordCount ? maxWords : maxWords - wordCount}</span> words at the top of your image.
                    </small>
                </div>
            </div>
        </label>
        <input
            id="headline"
            ref={register}
            name="headline"
            label="Top text"
            placeholder="Top text"
            className={`big-text-field uk-input uk-form-large uk-border-rounded`}
            defaultValue={headline ? headline : ""}
            onChange={handleChange}
            onKeyUp={(e) => maxWordCount(e, maxWords)}
            onKeyDown={(e) => maxWordCount(e, maxWords)}
            autoComplete="off"
            autoCorrect="off"
            />
        <small style={{margin: "5px"}} className="uk-position-bottom-right uk-text-primary">{wordCount} words</small>
        </>
    )
}

export default HeadlineInput;
