import React, {useState, useEffect} from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import { useSpring, useGesture, animated as a, interpolate } from "react-spring";
import { useDrag } from "react-use-gesture";
import axios from "axios"
import useCovidData from "../components/Hooks/useCovidData"
import style from "./Data.module.css";
import infectedIcon from "../images/icons/Infected.svg"
import deathIcon from "../images/icons/Deaths.svg"

const ChartData = ({bind, dataExpanded, location, ...props}) => {
    const {covidData, isLoading} = useCovidData();
    const globalCases = covidData.diff && covidData.diff[0].total_cases
    const globalDeaths = covidData.diff && covidData.diff[0].total_deaths
    const usCases = covidData.current && covidData.current.cases
    const usDeaths = covidData.current && covidData.current.deaths
    const currentCases = usCases && usCases.toLocaleString(`en-US`)
    const currentRecovered = covidData.current && covidData.current.recovered.toLocaleString(`en-US`)
    const currentDeaths = usDeaths && usDeaths.toLocaleString(`en-US`)
    const caseTimeline = covidData.current && covidData.caseTimeline
    const recoverTimeline = covidData.current && covidData.recoverTimeline
    const deathTimeline = covidData.current && covidData.deathTimeline

    const usPercentCases = covidData.current ? (parseInt(covidData.current.cases)/parseInt(globalCases)) * 100 : null
    const usPercentDeaths = covidData.current ? (parseInt(covidData.current.deaths)/parseInt(globalDeaths)) * 100 : null

    const images = useStaticQuery(graphql`
        query imagesQuery {
            deaths: file(relativePath: { eq: "icons/Deaths.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            infected: file(relativePath: { eq: "icons/Infected.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            recovered: file(relativePath: { eq: "icons/Recovered.png" }) {
                name
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const dataStyle = useSpring({
        marginTop: dataExpanded === 1 ? "0px" : "-110px",
        // minHeight: dataExpanded === 1 ? "64px" : "24px",
        config: { mass: 1, tension: 400, friction: 50 }
    });

    return (
        <a.section style={dataStyle} className={`${style.sparkLinesContainer}`}>
            <div className="uk-container">
            {location !== "/" ?
                <div className="uk-position-top-left">
                    <a style={{padding: "8px"}} className="uk-link-muted" href={`/`}>
                        <span data-uk-icon="icon: arrow-left; ratio: 0.6" /><span data-uk-icon="icon: home; ratio: 0.7" />
                    </a>
                </div>
            : null}
                <div className="uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-4@s uk-flex-center uk-visible@s" data-uk-grid>
                    <div>
                        <div className={`${style.dataCard} uk-card uk-card-small uk-card-warning uk-box-shadow-small`}>
                            <div style={{position: "absolute", left: "0", right: "0", bottom: "0"}}>
                                {
                                    isLoading ? null :
                                    <Sparklines style={{opacity: "0.5"}} data={caseTimeline}>
                                        <SparklinesLine color="#fff" />
                                    </Sparklines>
                                }
                            </div>
                            <div className={`${style.dataCardHeader} uk-card-header`}>
                                <div className="uk-grid uk-grid-collapse uk-flex-middle" data-uk-grid>
                                    <div className="uk-width-auto">
                                        <Img
                                            fluid={images.infected.childImageSharp.fluid}
                                            isLoading="eager"
                                            style={{width: "12px"}}
                                            alt={`The number of people infected with Covid-19 today.`}
                                            />
                                    </div>
                                    <div className="uk-width-expand">
                                        <p className="uk-text-small uk-text-uppercase">US&ndash;Infected</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${style.dataCardBody} uk-card-body`}>
                                <div className="uk-width-expand uk-position-relative">
                                {
                                    isLoading ?
                                    <div className="uk-flex uk-flex-center uk-flex middle"><span data-uk-spinner="ratio: 0.75" /></div>
                                    :
                                    <p className="uk-h3 uk-margin-remove uk-padding-remove uk-text-center">
                                        {currentCases}
                                        {usPercentCases ? <span style={{display: "block", fontSize: "9px", margin: "0"}}>{`${parseFloat(usPercentCases).toFixed(2)}%`} of world total</span> : null}
                                    </p>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`${style.dataCard} uk-card uk-card-small uk-card-danger uk-box-shadow-small`}>
                            <div style={{position: "absolute", left: "0", right: "0", bottom: "0"}}>
                                {
                                    isLoading ? null :
                                    <Sparklines style={{opacity: "0.5"}} data={deathTimeline}>
                                        <SparklinesLine color="#fff" />
                                    </Sparklines>
                                }
                            </div>
                            <div className={`${style.dataCardHeader} uk-card-header`}>
                                <div className="uk-grid uk-grid-collapse uk-flex-middle" data-uk-grid>
                                    <div className="uk-width-auto">
                                        <Img
                                            fluid={images.deaths.childImageSharp.fluid}
                                            style={{width: "12px"}}
                                            alt={`The number of people infected with Covid-19 today.`}
                                            />
                                    </div>

                                    <div className="uk-width-expand uk-margin-small-right">
                                        <p className="uk-text-small uk-text-uppercase">US&ndash;Dead</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${style.dataCardBody} uk-card-body`}>
                                <div className="uk-width-expand">
                                {
                                    isLoading ?
                                    <div className="uk-flex uk-flex-center uk-flex middle"><span data-uk-spinner="ratio: 0.75" /></div>
                                    :
                                    <p className="uk-h3 uk-margin-remove uk-padding-remove uk-text-center">
                                        {currentDeaths}
                                        {usPercentCases ? <span style={{display: "block", fontSize: "9px"}}>{`${parseFloat(usPercentDeaths).toFixed(2)}%`} of world total</span> : null}
                                    </p>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="uk-grid uk-grid-small uk-child-width-1-3 uk-hidden@s uk-text-center uk-flex-center uk-margin-remove-vertical" data-uk-grid>
                    <div>
                        <div className={`${style.dataMobileCard} uk-text-warning`}>
                            <div className="uk-width-expand">
                                <img src={infectedIcon} width="16" data-uk-svg />
                                <p className={`${style.dataMobileCardLabel}`}>{isLoading ? "---" : currentCases}</p>
                                {usPercentCases ?
                                <p className={`${style.pixelFont}`}>
                                    {`${parseFloat(usPercentCases).toFixed(2)}%`} of world total
                                </p>
                                :null}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`${style.dataMobileCard} uk-text-danger`}>
                            <div className="uk-width-expand">
                                <img src={deathIcon} width="16" data-uk-svg />
                                <p className={`${style.dataMobileCardLabel}`}>{isLoading ? "---" : currentDeaths}</p>
                                {usPercentCases ?
                                <p className={`${style.pixelFont}`}>
                                    {`${parseFloat(usPercentDeaths).toFixed(2)}%`} of world total
                                </p>
                                :null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a.section>

    )
}

export default ChartData
