import React, { useState, useEffect, useRef, useReducer } from "react";
import {countriesList} from "./Countries";
import './Autocomplete.css';

const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return inst => {
        for (const ref of filteredRefs) {
            if (typeof ref === 'function') {
                ref(inst);
            } else if (ref) {
                ref.current = inst;
            }
        }
    };
};

async function validateCountry(country) {
    console.log("INCOMING COUNTRY", country)
    const result = await countriesList.filter(
        c => c.name.toLowerCase().includes(country.name.toLowerCase()))
    console.log("VALIDATE COUNTRY", result)
    return result;
}

const CountrySelect = ({register, required, errors, setValue, ...props}) => {
    const countryRef = useRef();
    const [items, setItems] = useState(countriesList);
    const [selected, setSelected] = useState("")
    const [isCountry, setIsCountry] = useState(false)
    const [state, setState] = useState({
        activeItem: 0,
        filteredItems: [],
        displayItems: false,
        inputValue: ''
    })

    function handleAddItems(country) {
        validateCountry(country)
        .then(function (response) {
            console.log("RESPONSE", response[0])
            setIsCountry(true)
            setValue('countrySelect', response[0].name)
            setSelected(response)
        })
    }

    // function handleAddItems(country) {
    //     const newCountry = countriesList.filter(item => item.name.toLowerCase().includes(country.name.toLowerCase()))
    //     setValue('countrySelect', newCountry[0].name)
    //     setSelected(newCountry)
    // }

    const handleCheck = (e) => {
        console.log("CHECKED", e.target.checked)
    }

    const handleChange = (e) => {
        const filteredItems = items.filter(
            x => x.name.toLowerCase().includes(e.currentTarget.value.toLowerCase())
        );
        setState({
            activeItem: 0,
            filteredItems,
            displayItems: true,
            inputValue: e.currentTarget.value
        });
    };

    const handleClick = (e) => {
        setState({
            activeItem: 0,
            filteredItems: [],
            displayItems: false,
            inputValue: e.currentTarget.innerText
        });
        countryRef.current.value = "";
        e.currentTarget.blur();
        const c = items.filter(i => i.name.includes(e.currentTarget.innerText.trim()));
        handleAddItems(...c)
    };

    const handleKeyDown = (e) => {
        const { activeItem, filteredItems } = state;
        if (e.key === "Enter") {
            setIsCountry(false)
            const inList = filteredItems && items.includes(filteredItems[activeItem]);
            if(!inList || !countryRef.current.value) return;
            setState({
                activeItem: 0,
                filteredItems: [],
                displayItems: false,
                inputValue: filteredItems[activeItem]
            });
            countryRef.current.value = "";
            e.currentTarget.blur();
            handleAddItems(filteredItems[activeItem]);
        }
        else if (e.keyCode === 38) {
            e.preventDefault();
            if (activeItem === 0) {
                return;
            }
            setState({
                activeItem: activeItem - 1,
                filteredItems,
                displayItems: true,
                inputValue: e.currentTarget.value
            });
        }
        else if (e.keyCode === 40) {
            e.preventDefault();
            if ((filteredItems && activeItem === filteredItems.length - 1) || activeItem >= 4) {
                return;
            }
            setState({
                activeItem: activeItem + 1,
                filteredItems,
                displayItems: true,
                inputValue: e.currentTarget.value
            });
        }
    }

    return (
        <div className="uk-width-1-1">
            <label htmlFor="countrySelect" className="uk-text-small uk-text-muted uk-margin-small-bottom">
                <div className="uk-grid uk-grid-collapse">
                    <div className="uk-width-auto">
                        {!isCountry || errors.countrySelect ?
                            <small className="uk-text-danger uk-text-bold">* Required. </small>
                            : ""
                        }
                    </div>
                </div>
            </label>
            <input
                id="countrySelect"
                ref={mergeRefs(register({ required: true}), countryRef)}
                name="countrySelect"
                label="Country"
                placeholder="Country"
                className={`uk-input uk-form-large uk-width-expand`}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                />

            {state.displayItems && state.inputValue.length && state.filteredItems ?
                <div className="uk-width-1-1 list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                    <ul className="uk-list">
                    {
                        state.filteredItems.map((item, index) => {
                            return (
                                <li
                                    className={`${state.activeItem === index ? "active-item" : "default-item"}`}
                                    key = {item.name}
                                    onClick = {handleClick}>
                                    <div className="uk-width-1-1 uk-text-truncate">
                                        {item.name}
                                    </div>
                                </li>
                            )
                        }).slice(0, 5)
                    }
                    </ul>
                </div>
                : null
            }
            <div className="uk-margin-top uk-grid uk-grid-collapse uk-flex-top uk-flex-left">
                <div className="uk-width-auto">
                    <input style={{height: "20px", width: "20px"}}
                    id="public_post"
                    name="public_post"
                    ref={register()}
                    type="checkbox"
                    defaultChecked={true}
                    onChange={handleCheck}
                    className="uk-checkbox uk-border-circle uk-margin-small-right" />
                </div>
                <div className="uk-width-expand">
                <label htmlFor="public_post">
                    <p className="uk-margin-remove uk-padding-remove"><span className="uk-text-bold">@itsnotyouits_US</span> on <span className="uk-text-primary" data-uk-icon="icon: twitter; ratio: 1.25" /></p>
                    <p style={{fontSize: "10px"}}
                        className="uk-text-muted uk-width-4-5 uk-margin-remove uk-padding-remove">
                        This will publicly share your post on the @itsnotyouits_US Twitter feed.</p>
                </label>
                </div>
            </div>
        </div>
    )
}

export default CountrySelect;
