import React, {
    useState,
    useCallback
} from 'react'
import Cropper from 'react-easy-crop'
import {
    getOrientation
} from 'get-orientation/browser'
import {
    getCroppedImg,
    getRotatedImage
} from './canvasUtils'

import camera from "../../images/icons/camera.svg"
import style from "./Cropper.module.css"

const reduce = require('image-blob-reduce')();

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    // '6': 90,
    '8': -90,
}

const readFile = (file) => {
    return new Promise(resolve => {
        reduce.toBlob(file, { max: 1600 }).then(
            blob => {
                const reader = new FileReader()
                reader.addEventListener('load', () => resolve(reader.result), false)
                reader.readAsDataURL(blob)
            }
        );
    })
}

const LoadingImage = props =>
    <div>
        <div className="uk-width-expand uk-text-center uk-margin-bottom" data-uk-spinner />
        <small>JUST A SEC</small>
    </div>

const MobileInput = ({isLoading, register, handleFileChange, ...props}) => {

    if(isLoading) return <LoadingImage />

    return (
        <div className="uk-hidden@s">
            <div className="uk-margin-remove-vertical uk-padding-remove">
                <div
                    className="capture-picture uk-text-danger uk-width-1-1 uk-border-rounded uk-form-custom uk-padding"
                    data-uk-form-custom>
                    <label
                        htmlFor="capturePhoto">
                        <img width="128" src={camera} />
                        <input
                            id="capturePhoto"
                            name="picture"
                            ref={register({required: true})}
                            type="file"
                            className="upload-file-text"
                            onChange={handleFileChange}
                            accept="image/*"
                            capture />
                    </label>
                </div>
            </div>
            <p className="uk-width-1-4 uk-text-small uk-text-muted uk-margin-large-bottom uk-margin-auto-left uk-margin-auto-right uk-heading-line"><span>OR</span></p>
            <label
                htmlFor="fromPhotoAlbum"
                className="uk-text-muted uk-text-small uk-margin-large-top">
                <input
                    id="fromPhotoAlbum"
                    name="picture"
                    ref={register({required: true})}
                    type="file"
                    className="upload-file-text"
                    onChange={handleFileChange}
                    accept="image/*" />
                    <span data-uk-icon="icon: cloud-upload; ratio: 2" />
            </label>
        </div>
    )
}

const BrowserInput = ({isLoading, register, handleFileChange, ...props}) => {

    const handleDragover = e => {
        const el = document.getElementById('dragPlaceHolder')
        el.classList.add('capture-picture-dragover')
    }

    const handleDragleave = e => {
        const el = document.getElementById('dragPlaceHolder')
        el.classList.remove('capture-picture-dragover')
    }

    return (
        <div className="uk-visible@s">
            <p style={{fontSize: "10px"}} className="uk-text-muted uk-text-center uk-text-uppercase">Pictures only please!</p>
            <div className="uk-placeholder uk-margin-remove-vertical uk-padding-remove">
                <div id="dragPlaceHolder" onDragOver={handleDragover} onDragLeave={handleDragleave} onDrop={handleDragleave}
                    className="capture-picture uk-text-danger uk-width-1-1 uk-border-rounded uk-form-custom uk-padding" data-uk-form-custom>
                    <div>
                        <span className="uk-display-block" data-uk-icon="icon: cloud-upload; ratio: 7" />
                        <small>Click to upload or drag your picture here.</small>
                    </div>
                    <input
                        name="picture"
                        ref={register({required: true})}
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                        capture />
                </div>
            </div>
        </div>
    )
}

const CropImage = ({register, required, setSelectedImage, toggleModal, classes, ...props}) => {
    const [imageSrc, setImageSrc] = useState(null)
    const [filename, setFilename] = useState("")
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
        setIsLoading(false)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            setCroppedImage(croppedImage)
            setSelectedImage({
                filename: filename,
                dataURL: imageSrc,
                blob: croppedImage
            })
        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])

    const setImageData = async e => {
        const isImage = e.target.files[0].type.split('/')[0] === "image"
        if (isImage && e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setFilename(file.name)
            let imageDataUrl = await readFile(file)
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setImageSrc(imageDataUrl)
        }
    }

    const handleFileChange = e => {
        setIsLoading(true)
        setImageData(e)
    }

    return (
        <div className={`uk-height-viewport`}>
            {imageSrc ? (
                <div className={`${style.cropperContainer} uk-container`}>
                    <p style={{fontSize: "10px"}} className="uk-text-muted uk-text-center uk-text-uppercase uk-hidden@s">Resize and rotate</p>
                    <div style={{paddingBottom: "100%"}} className={`uk-container uk-container-expand uk-position-relative uk-box-shadow-large`}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={1 / 1}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            />
                    </div>

                    <div className="uk-grid uk-grid-large uk-margin-large uk-flex-center uk-child-width-auto">
                        <div className="uk-margin-large-right">
                            <button
                                type="button"
                                className="uk-button uk-button-large uk-button-text cta-button cta-button-prev"
                                onClick={toggleModal}>
                                Back
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="uk-button uk-button-large uk-button-text cta-button cta-button-next"
                                onClick={showCroppedImage}
                                >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="uk-flex uk-flex-column uk-flex-stretch">
                    <div className="uk-text-center uk-margin-xlarge-bottom">
                        <div className="uk-visible@s">
                            <BrowserInput
                                isLoading={isLoading}
                                register={register}
                                handleFileChange={handleFileChange} />
                        </div>
                        <div className="uk-hidden@s">
                            <MobileInput
                                isLoading={isLoading}
                                register={register}
                                handleFileChange={handleFileChange} />
                        </div>
                    </div>
                    {!isLoading ?
                    <div className="uk-text-center">
                        <button
                            type="button"
                            className="uk-button uk-button-large uk-button-text cta-button"
                            onClick={toggleModal}>
                            <span className="uk-padding-large">Cancel</span>
                        </button>
                    </div>
                    : null
                    }
                </div>
            )}
        </div>
    )
}

export default CropImage;
