import React, { useState, useEffect, useRef } from "react"

export const removeBreaks = str => {
    // console.log("REMOVE BREAKS", [arr], typeof arr)
    const index = [str].findIndex(el => el && el.match(/\r?\n|\r/g));

    if (index === -1)
        return [str];

    const newArray = [
  ...[str].slice(0, index),
  ...[str][index].split(/\r?\n|\r/),
  ...[str].slice(index + 1, [str].length)
];

    return removeBreaks(newArray);
}

export const removeEmptyElements = str => {
    const index = [str].findIndex(el => el && el.trim() === '');

    if (index === -1)
        return [str];

    [str].splice(index, 1);

    return removeEmptyElements([str])
};

export const maxWordCount = (e, maxWords) => {
    const backspace = 8;
    const del = 46;
    const valid_keys = [del, backspace];
    let val = e.target.value
    let words = val.split(' ');

    if (e.key === "Enter") {
        val.replace(/[\r\n\v]+/g, "")
        e.preventDefault()
        return;
    }

    if (words.length > maxWords && valid_keys.indexOf(e.keyCode) == -1) {
        words.length = maxWords;
        val = words.join(' ');
        e.preventDefault();
    }
}

const SetCounts = value => {
    const [text, setText] = useState("")
    const [charCount, setCharCount] = useState(0)
    const [wordCount, setWordCount] = useState(0)

    const trimmedValue = value.trim();
    let words = removeEmptyElements(trimmedValue)
    words = removeBreaks(trimmedValue)
    words = trimmedValue.split(' ');

    setText(value)
    setCharCount(trimmedValue.length)
    setWordCount(value === '' ? 0 : words.length)
}
