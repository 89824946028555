import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import { useForm } from "react-hook-form";
import useCovidData from "../Hooks/useCovidData"
import {countriesList} from "../CountrySelect/Countries";
import CropImage from "../CropImage"
import CountrySelect from '../CountrySelect';
import MessageInput from "./MessageInput"
import HeadlineInput from "./HeadlineInput"
import ShareButtons from "../SocialShare/ShareButtons"

async function postMaskie(formData) {
    const endpoint = process.env.GATSBY_ISTNOTYOU_POST;
    const result = await axios.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Itsus-Token': process.env.GATSBY_ISTNOTYOU_TOKEN
        }
    })
    const res = await result.data;
    return res;
}


const Form = ({setNewPost, toggleModal, ...props}) => {

    const {covidData} = useCovidData();
    const currentCases = covidData.current && covidData.current.cases.toLocaleString(`en-US`)
    const currentDeaths = covidData.current && covidData.current.deaths.toLocaleString(`en-US`)
    const { register, handleSubmit, setValue, errors, touched, formState, watch } = useForm({mode: "onBlur",});
    const [selectedImage, setSelectedImage] = useState(null);
    const [enteredMessage, setEnteredMessage] = useState(false);
    const [headline, setHeadline] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [isSharing, setIsSharing] = useState({})
    const [isNotCountry, setIsNotCountry] = useState(false)
    const [formPage, setFormPage] = useState(false)

    const onSubmit = data => {
        setIsLoading(true)
        const body = {
            file: selectedImage.blob,
            country: data.countrySelect,
            headline: headline.trim(),
            message: message.trim(),
            public_post: data.public_post,
            timestamp: Date.now(),
            cases: currentCases ? currentCases : "",
            deaths: currentDeaths ? currentDeaths : ""
        }

        postMaskie(body)
        .then(function (response) {
            setIsLoading(false)
            setIsSharing(response)
        })
        .catch(function (error) {
            console.log("FIRST CATCH ERROR", error)
            console.log("FIRST CATCH ERROR", error.response)
            setIsLoading(false)
        })

    };

    const handleClose = (e) => {
        window.location.replace(`/post/${isSharing.id}`);
        toggleModal();
    }

    return (
        isSharing && isSharing.id ?
        <div>
            <div className="uk-text-center">
                <img
                    src={selectedImage.blob}
                    className="uk-border-rounded"
                    height="64"
                    width="64"
                    alt="Share this post"
                    />
            </div>
            <h2 className="uk-margin-top uk-margin-medium-bottom uk-text-center">Share the health</h2>
            <ShareButtons data={isSharing} url={`https://post.itsnotyouits.us/meta/${isSharing.id}`} />
            <button className="uk-button uk-button-small uk-button-text cta-button uk-margin-xlarge" onClick={handleClose}>Close</button>
        </div>
        :
        <form className="default-form" onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="uk-fieldset uk-text-left">
            {!selectedImage ?
                <CropImage register={register} toggleModal={toggleModal} setSelectedImage={setSelectedImage} />
                :
                !enteredMessage ?
                (<>
                    <div className="uk-position-relative uk-margin-medium">
                        <HeadlineInput
                            register={register}
                            setHeadline={setHeadline}
                            headline={headline}
                            />
                    </div>

                    <div className="uk-position-relative uk-margin">
                        <MessageInput
                            register={register}
                            setMessage={setMessage}
                            errors={errors}
                            message={message}
                            />
                    </div>

                    <div className="uk-grid uk-grid-large uk-margin-large uk-flex-center uk-child-width-auto">
                        <div className="uk-margin-large-right">
                            <button
                                type="button"
                                className="uk-button uk-button-large uk-button-text cta-button cta-button-prev"
                                onClick={() => setSelectedImage(!selectedImage)}>
                                Back
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="uk-button uk-button-large uk-button-text cta-button cta-button-next"
                                onClick={() => message && setEnteredMessage(true)}
                                >
                                Next
                            </button>
                        </div>
                    </div>
                </>)
                :
                (
                    <>
                    <div className="uk-position-relative uk-margin">
                        <CountrySelect
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            />
                    </div>
                    {!isLoading ?
                    <div className="uk-grid uk-grid-large uk-margin-large uk-flex-center uk-child-width-auto">
                        <div className="uk-margin-large-right">
                            <button
                                type="button"
                                className="uk-button uk-button-large uk-button-text cta-button cta-button-prev"
                                onClick={() => setEnteredMessage(!enteredMessage)}>
                                Back
                            </button>
                        </div>
                        <div>
                            <button
                                type="submit"
                                disabled={errors.length > 0}
                                className="uk-button uk-button-large uk-button-text cta-button cta-button-next">
                                {errors.length > 0 ? "Check for errors" : "Send It"}
                            </button>
                        </div>
                    </div>
                    :
                    <div className="uk-width-expand uk-text-center" data-uk-spinner="ratio: 0.8" />
                    }
                    </>
                )
            }
            </fieldset>
        </form>
    )
}

const MaskieForm = ({setNewPost, toggleModal, ...props}) => {

    return (
        <Form setNewPost={setNewPost} toggleModal={toggleModal} />
    )
}

export default MaskieForm;
