import React, {useState, useEffect} from "react";
import { useSpring, animated as a } from "react-spring"
import moment from "moment";
import style from "./Walkthrough.module.css";
import logo from "../../images/itsnotyouitsus-logo.png"

const Walkthrough = props => {
    const [preload, setPreload] = useState(true);
    const today = moment().toDate();

    const coverScreenStyle = useSpring({
        position: "fixed",
        top: preload ? "0%" : "-100%",
        height: "100%",
        zIndex: "1005",
        config: { mass: 1, tension: 600, friction: 50 },
    });

    useEffect(
        () => {
            const loader = document.getElementById('walkthroughPWA')
            if (!loader) return;
            if(!preload) {
                let timer2 = setTimeout(() => loader.remove(), 1300)
                if (prompt && "localStorage" in window) {
                    localStorage.setItem("WalkthroughNew", today);
                }
                return () => {
                    clearTimeout(timer2)
                }
            }
        }, [preload]
    )

    return (
        <a.div id="walkthroughPWA" style={coverScreenStyle} className={`uk-hidden@s`}>
            <div className={`${style.walkthroughContainer} uk-container`}>
                <div className="uk-margin-left uk-margin-right">
                    <div className="uk-text-center">
                        <img src={logo} className="uk-margin-large-bottom" width="96" />
                    </div>
                    <div>
                        <h1>There is no USA<br />without US.</h1>
                        <div className="uk-width-2-3@s">
                            <p className="uk-text-lead">Hello America.</p>
                            <p className="uk-text-lead">Nobody likes a mask, but think about US.</p>
                            <div className="uk-text-center uk-margin-xlarge-top">
                                <button className="uk-button uk-button-text cta-button"
                                    onClick={() => setPreload(false)}>Make your maskie <span data-uk-icon="icon: arrow-right; ratio: 1.15" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a.div>
    )
}
export default Walkthrough;
