import React, { useState, useEffect, useRef } from "react"
import { useSpring, useTransition, animated as a } from "react-spring";
import {removeBreaks, removeEmptyElements, maxWordCount} from "../Hooks/useWordCounter"

const MessageInput = ({register, required, errors, message, setMessage, ...props}) => {
    const maxWords = 10
    const [charCount, setCharCount] = useState(0)
    const [wordCount, setWordCount] = useState(0)

    const setCounts = value => {
        const trimmedValue = value.trim();
        let words = removeEmptyElements(trimmedValue)
        words = removeBreaks(trimmedValue)
        words = trimmedValue.split(' ');

        setMessage(value)
        setCharCount(trimmedValue.length)
        setWordCount(value === '' ? 0 : words.length)
    }

    const handleChange = e => {
        setCounts(e.target.value)
    };

    return (
        <>
        <label htmlFor="message" className="uk-text-small uk-text-muted uk-margin-small-bottom">
            <div className="uk-display-inline">
                <small>
                {(!message || errors.message) ?
                    <span className="uk-text-danger uk-text-bold">
                        * Required. </span>
                    :
                    null
                }
                Up to <span className="uk-text-bold">{!wordCount ? maxWords : maxWords - wordCount}</span> words at the image bottom.
                </small>
            </div>
        </label>
        <textarea
            id="message"
            ref={register({required: true})}
            name="message"
            label="Bottom text"
            placeholder="Bottom text"
            rows="2"
            defaultValue={message ? message : ""}
            className={`uk-textarea uk-form-large uk-border-rounded`}
            onChange={handleChange}
            onKeyUp={(e) => maxWordCount(e, maxWords)}
            onKeyDown={(e) => maxWordCount(e, maxWords)}
        />
        <small style={{margin: "5px"}} className="uk-position-bottom-right uk-text-primary">{wordCount} words</small>
        </>
    )
}

export default MessageInput;
