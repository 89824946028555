import React, {useState, useEffect} from "react";
import axios from "axios";
import moment from "moment"
import MaskieForm from "./MaskieForm";
import { useModal } from "../Hooks/useModal";

const Form = ({toggleModal, ...props}) => {

    const [serverError, setServerError] = useState();

    useEffect(
        () => {
            window.location.hash = 'image'
            try {
                window.addEventListener("hashchange", function(e) {
                    if(e.oldURL.length > e.newURL.length)
                        toggleModal()
                });
            } catch (e) {
                console.error("HASH CHANGE ERROR", e);
            }
            // return () => window.removeEventListener("hashchange")
        }, []
    )

    const handleSubmit = data => {
        console.log("FORM DATA FROM FORM COMPONENT", data)
        // const endpoint = `${ProjectUrls.DEFAULT}`;
        // const token = localStorage.getItem("token");
        // const body = {
        //     owner: JSON.parse(localStorage.user).id,
        //     name: data.name,
        //     start_date: data.startDate._d,
        //     end_date: data.endDate._d,
        //     brief: data.brief,
        //     info: data.info,
        // };
        //
        // if (token) {
        //     return axios({
        //         method: "POST",
        //         url: endpoint,
        //         data: body,
        //         headers: {
        //             authorization: `Token ${token}`
        //         }
        //     })
        //     .then((response) => {
        //         console.log("CREATE PROJECT RESPONSE", response)
        //         history.push(`/project/${response.data.id}`);
        //     })
        //     .catch((error) => {
        //         console.log("BIG ERROR", error);
        //     });
        // }
    }

    return (
        <section className="uk-height-1-1">
            <div className="uk-height-1-1 uk-container uk-container-small uk-position-z-index uk-position-relative">
                <div className="uk-width-4-5@s uk-width-2-3@l uk-width-4-5@xl uk-padding-small uk-margin-auto">
                    <MaskieForm
                        toggleModal={toggleModal}
                        {...props} />
                </div>
            </div>
        </section>
    )
}

export default Form
