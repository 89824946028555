import React, {useState, useEffect} from "react";

import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WeiboShareButton,
  WhatsappShareButton
} from "react-share";

import {
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon
} from "react-share";


const randomize = () => {
    return Math.floor((Math.random() * 1000) + 1);
}

const ShareButtons = ({data, url}) => {
    const quote = `${data.headline ? data.headline + ' ' : ""}${data.message}\n`
    const hashTags = "#ItsNotYouItsUS #DontBeaSelfishD #wearamask #peoplematter #maskup #maskmeme"
    const cacheBreakerUrl = `${url}/?cb=${randomize()}`

    return (
        <div className="uk-grid uk-child-width-1-3 uk-padding-remove uk-text-center" data-uk-grid>
            <div>
                <FacebookShareButton quote={quote} hashtag={hashTags} url={url}>
                    <FacebookIcon size={48} round={true} />
                </FacebookShareButton>
            </div>
            <div>
                <TwitterShareButton title={quote} hashtags={hashTags.replace(/#/g, '').split(' ')} url={cacheBreakerUrl}>
                    <TwitterIcon size={48} round={true} />
                </TwitterShareButton>
            </div>
            <div>
                <LinkedinShareButton title={quote} summary={hashTags} url={cacheBreakerUrl}>
                    <LinkedinIcon size={48} round={true} />
                </LinkedinShareButton>
            </div>
            <div>
                <WeiboShareButton title={quote} url={url}>
                    <WeiboIcon size={48} round={true} />
                </WeiboShareButton>
            </div>
            <div>
                <LineShareButton title={quote} url={url}>
                    <LineIcon size={48} round={true} />
                </LineShareButton>
            </div>
            <div>
                <PinterestShareButton description={quote} url={url}>
                    <PinterestIcon size={48} round={true} />
                </PinterestShareButton>
            </div>
            <div>
                <RedditShareButton title={quote} url={url}>
                    <RedditIcon size={48} round={true} />
                </RedditShareButton>
            </div>
            <div>
                <TumblrShareButton title={quote} tags={hashTags.replace(/#/g, '').split(' ')} url={url}>
                    <TumblrIcon size={48} round={true} />
                </TumblrShareButton>
            </div>
            <div>
                <WhatsappShareButton url={url}>
                    <WhatsappIcon size={48} round={true} />
                </WhatsappShareButton>
            </div>
        </div>
    )
}
export default ShareButtons
