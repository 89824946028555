import React, { useEffect } from "react";
import Notification from "../Notification";
import { useNotification } from "../Hooks/useNotification";
import image from "../../images/favicons/medical-mask-144.png";
import share from "../../images/AppleShare.png";
export const InstallPWA = ({...props}) => {
    const [notificationOpen, setNotificationOpen, toggleNotification] = useNotification();

    useEffect(
        () => {
            setNotificationOpen(true)
        }, []
    )
    return (
        <Notification
            isActive={notificationOpen}
            className={`monkey`}>
                <div style={{height: "45px", padding: "8px"}}
                    className="uk-card uk-background-default">
                    <span>Tap <img
                        src={share}
                        style={{margin: "auto 4px 8px"}}
                        className="uk-display-inline-block"
                        alt="Add to homescreen"
                        height="24"
                        width="24"
                        />
                        &nbsp;
                        then &quot;Add to Home Screen&quot;
                    </span>
                    <span className="uk-position-right uk-padding-xsmall" data-uk-icon="icon: close" onClick={() => setNotificationOpen(false)} />
                </div>
        </Notification>
    )
}
