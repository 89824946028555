import React, { useEffect, useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, animated as a, interpolate } from "react-spring";
import { useDrag, useScroll } from "react-use-gesture";
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import useIsIOS from "./Hooks/useIsIOS"
import { useModal } from "./Hooks/useModal";
import {InstallPWA} from "./InstallPWA"
import ChartData from "../data"
import Modal from "./Modal";
import Walkthrough from "./Walkthrough"
import Form from "./Form"
import "./Modal/Modal.css"
import "../styles/layout.css"
import actionButton from "../images/medical-mask.svg"

const Layout = ({ location, children }) => {
    const { isIPhone, isIPad, isIOS, isSafari, prompt } = useIsIOS();
    const [modalOpen, setModalOpen, toggleModal] = useModal();
    const [dataExpanded, setDataExpanded] = useState(1)
    const [preload, setPreload] = useState(true);
    const [mobile, setMobile] = useState(false);
    const [walkthrough, setWalkthrough] = useState();
    const pwaRef = useRef(false);
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
    `)

    useEffect(
        () => {
            try {
                const UIkit = require("uikit/dist/js/uikit");
                // const Icons = require("../../static/uikit-icons");
                const Icons = require("uikit/dist/js/uikit-icons");
                UIkit.use(Icons);

                if(window.innerWidth <= 640) {setMobile(true)}
                setWalkthrough(localStorage.getItem("WalkthroughNew"))
                const loader = document.getElementById('loadingOverlayPWA')
                if(location.pathname !== '/' || !navigator.standalone) {
                    loader.remove()
                }
                if (!loader) return;
                let timer1 = setTimeout(() => setPreload(false), 1000)
                let timer2 = setTimeout(() => loader.remove(), 1300)
                return () => {
                    clearTimeout(timer1)
                    clearTimeout(timer2)
                }
            } catch (e) {
                console.error("ONLOAD LAYOUT ERROR", e, e.response);
            }
        }, []
    )

    const coverScreenStyle = useSpring({
        width: "100%",
        height: "100%",
        opacity: preload ? "1" : "0",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: 10020,
        backgroundColor: mobile ? "#d7504e" : "#fff"
    });

    const bind = useDrag(({ args: [index], down, movement: [my], distance, direction: [x, y], delta: [yDelta], velocity }) => {
        const trigger = velocity > 0.2
        const gotDelta = Math.abs(yDelta) >= 1
        console.log(gotDelta)
        let dir = y < 0 ? -1 : 1
        if(!down && trigger) {
            setDataExpanded(dir)
        };
    })

    // useEffect(
    //     () => {
    //     }, [dataExpanded]
    // )

  return (
    <>
    <a.div id="loadingOverlayPWA" style={coverScreenStyle} />
    <ChartData bind={bind} dataExpanded={dataExpanded} location={location.pathname} />
    <div className="mobile-nav uk-width-1-1 uk-hidden@m">
        <div>
            <img
                src={actionButton}
                width="64"
                onClick={toggleModal}
                data-uk-svg
            />
        </div>
    </div>
    <a.main {...bind()}>{children}</a.main>
    {mobile && !walkthrough && <Walkthrough />}
    {prompt && <InstallPWA />}
    <Modal
        isActive={modalOpen}
        className="fullscreen-card">
        <div className="uk-width-1-1">
            <div className="uk-text-center">
                <Form toggleModal={toggleModal} />
            </div>
        </div>
    </Modal>
    </>
  )
}

export default Layout
