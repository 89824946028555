import React, {useState, useEffect, useContext} from "react";
import axios from "axios"
import moment from "moment";

const infectionTimelineData = data => {
    const chartNodes = data.sort((a, b) =>
            new Date(a.last_update).getTime() - new Date(b.last_update).getTime()
        ).map(d => {
            return (
                {
                    cases: d.cases - d.recovered,
                    recovered: d.recovered,
                    deaths: d.deaths
                }
            )
        });
    return chartNodes;
}

async function usaCovidData() {
    const diffByCountry = axios.get(`https://covid19-api.org/api/timeline`);
    const timelineByCountry = axios.get(`https://covid19-api.org/api/timeline/us`);
    const result = await axios.all([diffByCountry, timelineByCountry])
    const res = await result;
    return res;
}

function requestData(setCovidData, setIsLoading) {
    usaCovidData()
    .then(axios.spread((...responses) => {
        const current = responses[1].data[0]
        const timeline = infectionTimelineData(responses[1].data);
        const diff = responses[0].data;
        let caseTimeline = timeline.map(c => c.cases)
        caseTimeline = [1000000, ...caseTimeline.slice(Math.max(caseTimeline.length - 90, 1))]
        let recoverTimeline = timeline.map(r => r.recovered)
        let deathTimeline = timeline.map(d => d.deaths)
        deathTimeline = [140000, ...deathTimeline.slice(Math.max(deathTimeline.length - 90, 1))]
        const all = {
            current: current,
            diff: diff,
            caseTimeline: caseTimeline,
            recoverTimeline: recoverTimeline,
            deathTimeline: deathTimeline
        }
        // localForage.setItem('covidData', all);
        localStorage.setItem('covidData', JSON.stringify(all));
        setCovidData(all);
        setIsLoading(false);
    }))
    .catch((error) => {
        console.log("REQUEST ERROR", error)
    });
}

const useCovidData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [covidData, setCovidData] = useState([])

    useEffect(
        () => {
            let data = localStorage.getItem('covidData')
            data = data ? JSON.parse(data) : false

            if(data) {
                setCovidData(data)
            } else {
                console.log("NO DATA")
                setIsLoading(true)
                // requestData(setCovidData, setIsLoading);
            }
            requestData(setCovidData, setIsLoading);
            return() => console.log("CLEANUP INSTALL PROMPT", covidData);
        }, []
    );

    return {covidData, isLoading};
}

export default useCovidData;
